<template>
	<CCol sm="4">
		<nav class="side-bar-nav text-color-black bg-white rounded-sm shadow-sm p-3">
			<div
				v-for="refKey in Object.keys(PRODUCT_REFS)"
				:key="refKey"
				class="nav-item p-2"
				@click.prevent="handleClickNav(PRODUCT_REFS[refKey])"
			>
				{{ PRODUCT_REFS_TITLE[PRODUCT_REFS[refKey]] }}
			</div>
		</nav>
	</CCol>
</template>

<script>
import { PRODUCT_REFS, PRODUCT_REFS_TITLE } from '../enums/products';

export default {
	name: 'ProductEditSidebar',

	data() {
		return {
			PRODUCT_REFS,
			PRODUCT_REFS_TITLE,
		};
	},
	methods: {
		handleClickNav(refNames) {
			this.$emit('onClickNav', refNames);
		},
	},
};
</script>


<style lang="scss" scoped>
	.nav-item {
		cursor: pointer;
		font-size: rem(14);

		&:hover {
			background-color: $color-gray-100;
			border-radius: rem(4);
			color: $color-black-90;
			font-weight: 600;
		}
	}

	.side-bar-nav {
		position: sticky;
		top: rem(12);
		overflow-x: hidden;
		overflow-y: auto;
		max-width: rem(270);
		max-height: calc(100vh - 220px);
	}
</style>
