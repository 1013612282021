<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Price
			</h4>
			<CRow class="mb-4 pt-2">
				<CCol md="3">
					<div class="label">
						SRP price
					</div>
				</CCol>
				<CCol md="3">
					<div class="text-right typo-body-2" data-test-id="product-srp-price">
						{{ priceSrp }}
					</div>
				</CCol>
			</CRow>
			<CRow class="mb-4 pt-2">
				<CCol md="3">
					<div class="label">
						Minimum price
					</div>
				</CCol>
				<CCol md="3">
					<div class="text-right typo-body-2" data-test-id="product-min-price">
						{{ priceMin }}
					</div>
				</CCol>
			</CRow>
			<CRow class="mb-4" align-vertical="center">
				<CCol md="3">
					<div class="label">
						Franchise price
					</div>
				</CCol>
				<CCol md="3">
					<BaseInputNumber
						:value="priceSellingFranchise"
						:decimal-scale="2"
						data-test-id="product-selling-price-franchise"
						prepend-text="฿"
						disabled
						@input="handlePriceSellingFranchise"
					/>
				</CCol>
				<CCol class="pt-1">
					<div class="form-group-switch">
						<CSwitch
							id="allow-itec-franchise"
							:checked="isItecOverwriteFranchise"
							variant="3d"
							size="sm"
							class="switch-custom ml-0"
							color="success"
							disabled
							@update:checked="handleUpdateOverwritePriceFranchiseItec"
						/>
						<span class="typo-body-2 p">Allow ITEC to replace</span>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="3">
					<div class="label">
						Selling price
					</div>
				</CCol>
				<CCol md="3">
					<BaseInputNumber
						:value="priceSelling"
						:decimal-scale="2"
						data-test-id="product-selling-price"
						prepend-text="฿"
						disabled
						@input="handlePriceSelling"
					/>
				</CCol>
				<CCol class="pt-1">
					<div class="form-group-switch">
						<CSwitch
							id="allow-itec"
							:checked="isItecOverwrite"
							variant="3d"
							size="sm"
							class="switch-custom ml-0"
							color="success"
							disabled
							@update:checked="handleUpdateOverwritePriceItec"
						/>
						<span class="typo-body-2 p">Allow ITEC to replace</span>
					</div>
				</CCol>
			</CRow>
			<hr class="mt-5">
		</CCol>
	</CRow>
</template>
<script>

export default {
	props: {
		priceSrp: {
			type: String,
			default: null,
		},
		priceMin: {
			type: String,
			default: null,
		},
		priceSelling: {
			type: String,
			default: null,
		},
		priceSellingFranchise: {
			type: String,
			default: null,
		},
		isItecOverwrite: {
			type: Boolean,
			default: true,
		},
		isItecOverwriteFranchise: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		handlePriceSelling(number) {
			this.$emit('update:priceSelling', number);
		},
		handlePriceSellingFranchise(number) {
			this.$emit('update:priceSellingFranchise', number);
		},
		handleUpdateOverwritePriceItec(checked) {
			this.$emit('update:isItecOverwrite', checked);
		},
		handleUpdateOverwritePriceFranchiseItec(checked) {
			this.$emit('update:isItecOverwriteFranchise', checked);
		},
	},
};
</script>
