<template>
	<div data-test-id="remarks-container">
		<h4 class="my-4">
			Remark
		</h4>
		<RemarkCreateAndList
			:remarks="list"
			:is-creating="remark.isCreating"
			data-test-id="remarks-textarea"
			@onAddRemark="handleAddRemark"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RemarkCreateAndList from '@/components/RemarkCreateAndList.vue';

export default {
	name: 'ProductEditRemark',
	components: {
		RemarkCreateAndList,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapState('products', {
			remark: 'remark',
		}),
	},
	methods: {
		...mapActions({
			createProductRemark: 'products/createProductRemark',
			getProduct: 'products/getProduct',
		}),
		async handleAddRemark(message) {
			const { id } = this.$route.params;
			const params = { message };
			await this.createProductRemark({ id, params });
		},
	},
};
</script>
