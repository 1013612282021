<template>
	<CRow data-test-id="warranty-days-container">
		<CCol>
			<h4 class="my-4">
				Warranty
			</h4>
			<CRow>
				<CCol lg="6">
					<BaseInputNumber
						:value="customerWarrantyDays"
						data-test-id="customer-warranty-days"
						label="Customer warranty days (day)"
						text-align="left"
						@input="(value) => $emit('update:customerWarrantyDays', Number(value))"
					/>
				</CCol>
				<CCol lg="6">
					<BaseInputNumber
						:value="supplierWarrantyDays"
						data-test-id="supplier-warranty-days"
						label="Supplier warranty days (day)"
						text-align="left"
						@input="(value) => $emit('update:supplierWarrantyDays', Number(value))"
					/>
				</CCol>
				<CCol lg="12">
					<small class="form-text text-muted w-100 mt-2">
						When enter equal or greater than 9999 will be lifetime warranty
					</small>
				</CCol>
			</CRow>
			<hr class="mt-5">
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ProductEditWarranty',

	props: {
		warranty: {
			type: Object,
			default: () => ({}),
		},
		customerWarrantyDays: {
			type: Number,
			default: null,
		},
		supplierWarrantyDays: {
			type: Number,
			default: null,
		},
	},
};
</script>
