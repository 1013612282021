<template>
	<CRow>
		<CCol>
			<CRow>
				<CCol md="7">
					<h6 data-test-id="title">
						Product image
					</h6>
					<div class="description typo-helper-text color-black-45">
						<span>
							The images will show in many locations on the website like product list and product detail. The first image from here will be shown as a cover image of the product.
						</span>
					</div>
				</CCol>
				<CCol md="5" class="text-right">
					<CButton
						class="transparent"
						color="secondary"
						@click="$refs['media-library'].open()"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Add image
					</CButton>
				</CCol>
				<CCol>
					<draggable
						:value="productImages"
						tag="div"
						ghost-class="is-placeholder-item"
						class="product-image-previews"
						@input="handleImageOrder"
					>
						<template v-if="productImages.length">
							<div
								v-for="(image, key) in productImages"
								:key="key"
								class="product-image"
							>
								<a :href="image.imageUrls.original" target="_blank">
									<img :src="image.imageUrls.thumbnail" alt="">
								</a>

								<CButton
									class="btn-delete-image"
									@click="handleDeleteImage(image.id)"
								>
									<CIcon name="cil-trash" />
								</CButton>
							</div>
						</template>
						<div v-else class="product-no-image" data-test-id="product-no-image">
							<img
								src="/assets/images/product-placeholder.svg"
								alt="Empty image"
								width="40"
								class="empty-image mb-1"
							>
							<p class="typo-body-2 color-black-45">
								No product image
							</p>
						</div>
					</draggable>
					<ul class="product-image-helper">
						<li>- Recommended image size is 1000x1000px.</li>
						<li>- Maximum image for each product is 10 images.</li>
						<li>- Support only PNG, JPG, and JPEG.</li>
					</ul>
				</CCol>
			</CRow>
			<ModalMediaLibrary
				ref="media-library"
				:close-after-submit="false"
				@onSubmit="handleSubmitMedia"
			/>
		</CCol>
	</CRow>
</template>

<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';

export default {
	name: 'ProductEditImage',

	components: {
		draggable,
		ModalMediaLibrary,
	},
	props: {
		files: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			maxProductFile: 10,
			productImages: this.files,
		};
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),

		handleSubmitMedia(images) {
			// Filter existing image out
			const newImages = images.filter((image) => {
				return !this.productImages.some((oldImage) => oldImage.id === image.id);
			});

			const totalImage = newImages.length + this.productImages.length;

			if (totalImage <= this.maxProductFile) {
				this.productImages = [
					...this.productImages,
					...newImages,
				];

				this.$refs['media-library'].close();

				this.$emit('onFileChange', this.productImages);
			} else {
				this.showToast({
					type: 'danger',
					header: 'Can not add image to the product',
					content: 'Allow only 10 images for each product.',
				});
			}
		},
		handleImageOrder(sortedList = []) {
			this.productImages = sortedList;

			this.$emit('onFileChange', this.productImages);
		},
		handleDeleteImage(id = null) {
			if (id) {
				this.productImages = this.productImages.filter((image) => image.id !== id);
				this.$emit('onFileChange', this.productImages);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.product-image-previews {
		display: flex;
		flex-wrap: wrap;
		background-color: $color-gray-100;
		padding: 6px;
		margin-top: rem(16);
		min-height: rem(246);
		border-radius: 4px;

		.product-image {
			position: relative;
			margin: 6px;
			width: rem(105);
			height: rem(105);
			cursor: move;

			a {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				cursor: move;

				&::before {
					position: absolute;
					width: 100%;
					height: 100%;
					content: "";
					background: $color-black;
					opacity: 0;
					transition: opacity 300ms;
				}

				&:hover {
					&::before {
						opacity: 0.08;
					}
				}
			}

			img {
				width: 100%;
				height: auto;
				border-radius: 2px;
				border: 1px solid $color-gray-300;
			}
		}
	}

	.product-no-image {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		p {
			margin-bottom: 0;
		}
	}

	.product-image-helper {
		list-style: none;
		margin-top: rem(12);
		padding: 0;

		li {
			@include typo-helper-text;

			color: $color-black-45;
			margin-bottom: 2px;
		}
	}

	.is-placeholder-item {
		img {
			opacity: 0.5;
		}
	}

	.btn-delete-image {
		position: absolute;
		right: rem(8);
		bottom: rem(8);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-25;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}
</style>
