<template>
	<div>
		<CRow>
			<CCol>
				<CTextarea
					v-model.trim="message"
					rows="3"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="text-right">
				<CButton
					:disabled="!message || isCreating"
					color="secondary"
					@click="handleAddRemark"
				>
					{{ isCreating ? 'Adding remark' : 'Add new remark' }}
				</CButton>
				<hr class="mt-4">
			</CCol>
		</CRow>
		<CRow
			v-if="remarks.length"
			class="mt-2"
		>
			<CCol
				v-for="remark in shownRemarks"
				:key="remark.id"
				md="12"
			>
				<div class="remark-box border rounded-sm p-3 mb-3">
					<div class="remark-item typo-body-2 mb-3">{{ remark.message }}</div>
					<div class="typo-caption-2">
						By
						<router-link
							:to="{
								name: 'UserEdit',
								params: {id: remark.user.id}
							}"
						>
							{{ remark.user.username }}
						</router-link>
						| {{ remark.createdAt }}
					</div>
				</div>
			</CCol>
		</CRow>
		<CRow v-else>
			<CCol>
				<BaseNoItemBanner text="There is no remarks." />
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<CButton
					v-if="remarks.length > LATEST_TWO_REMARK"
					color="tertiary"
					@click="isShowAllRemark = !isShowAllRemark"
				>
					{{ isShowAllRemark ? 'Show less remarks' : 'Show all remarks' }}
				</CButton>
			</CCol>
		</CRow>
	</div>
</template>

<script>
export default {
	name: 'RemarkCreateAndList',
	props: {
		remarks: {
			type: Array,
			default: () => [],
		},
		isCreating: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShowAllRemark: false,
			message: '',
		};
	},
	computed: {
		LATEST_TWO_REMARK: () => 2,
		shownRemarks() {
			return this.isShowAllRemark
				? this.remarks
				: this.remarks.slice(0, this.LATEST_TWO_REMARK);
		},
	},
	methods: {
		handleAddRemark() {
			this.$emit('onAddRemark', this.message);
			this.message = '';
		},
	},
};
</script>


<style lang="scss" scoped>
	.remark-box {
		border-color: $color-alto;

		.remark-item {
			white-space: pre-wrap;
		}
	}
</style>
