<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Specification
			</h4>
			<CRow>
				<CCol md="6">
					<BaseDropDown
						:options="sets"
						:value="attributeSet"
						:close-on-select="true"
						data-test-id="attribute-set"
						class="multiple-options"
						label="name"
						label-drop-down="Attribute Set"
						@input="(value) => $emit('update:attributeSet', value)"
					/>
				</CCol>
			</CRow>
			<CRow
				v-for="set in attributeSet.attributeKeys"
				:key="set.id"
				data-test-id="attributes"
			>
				<CCol>
					<CInput
						v-model="set.value"
						:label="set.name"
					/>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	props: {
		attributeSet: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selected: null,
		};
	},
	computed: {
		...mapGetters({
			sets: 'attributeSets/attributeList',
		}),
	},
	async mounted() {
		await this.getAttributeSets({ per_page: 'all' });
		this.selected = {
			id: this.attributeSet.id,
			name: this.attributeSet.name,
		};
	},
	methods: {
		...mapActions({
			getAttributeSets: 'attributeSets/getAttributeSets',
		}),
		handleUpdateAttributeKey(index, value) {
			this.$emit('onUpdateAttributeKey', index, value);
		},
	},
};
</script>
