<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Description
			</h4>
			<CRow>
				<CCol>
					<label>Short description</label>
					<BaseRichTextEditor
						:value="shortDescription"
						data-test-id="short-description"
						name="contentTH"
						@input="(value) => $emit('update:shortDescription', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<label>Full description</label>
					<BaseRichTextEditor
						:value="fullDescription"
						data-test-id="full-description"
						name="contentTH"
						@input="(value) => $emit('update:fullDescription', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CTextarea
						v-model.trim="$v.localShortAttribute.$model"
						label="Short attribute description"
						rows="3"
						add-input-classes="short-attribute"
						:is-valid="!$v.localShortAttribute.$error && null"
						:invalid-feedback="shortAttributeError"
						@input="(value) => $emit('update:shortAttribute', value)"
					/>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';

export default {
	name: 'ProductEditDescription',

	validations: {
		localShortAttribute: {
			maxLength: maxLength(200),
		},
	},

	props: {
		shortDescription: {
			type: String,
			default: null,
		},
		fullDescription: {
			type: String,
			default: null,
		},
		shortAttribute: {
			type: String,
			default: null,
		},
	},

	data() {
		return {
			localShortAttribute: '',
		};
	},

	computed: {
		shortAttributeError() {
			if (!this.$v.localShortAttribute.maxLength) {
				return this.$t('global.error.maximumLength', { field: 'short attribute description', number: 200 });
			}

			return null;
		},
	},

	created() {
		this.localShortAttribute = this.shortAttribute;
	},
};
</script>


<style lang="scss" scoped>
	::v-deep .ql-container {
		height: rem(200);
	}
</style>