<template>
	<CRow>
		<CCol>
			<CRow>
				<CCol md="7">
					<h6>
						Product image360
					</h6>
					<div class="typo-helper-text color-black-45">
						<span>
							Image360 will be shown in product detail page. Allow only 1 image360 generated link from <a href="https://orbitvu.com/" class="link" target="_blank">Orbitvu</a> service. Recommended cover image size is 1000x1000px.
						</span>
					</div>
				</CCol>

				<CCol md="5" class="text-right">
					<CButton
						:disabled="isMaxValue"
						class="transparent"
						color="secondary"
						data-test-id="btn-add360-item"
						@click="handleAddItem"
					>
						<CIcon class="mr-2" name="cil-plus" /> Add image360
					</CButton>
				</CCol>
			</CRow>

			<CRow>
				<CCol>
					<BaseInputMedia
						v-for="(threeSixtyImage, index) in localThreeSixtyImages"
						:key="threeSixtyImage.key"
						:file="threeSixtyImage.file"
						:link="threeSixtyImage.link"
						:index="index"
						label="Image360 link"
						class="three-sixty-image-item"
						data-test-id="three-sixty-image-item"
						placeholder="Add image360 link here"
						@onInputImage="handleInputImage"
						@onDelete="handleDeleteItem"
						@onLinkChange="handleLinkChange"
					/>
				</CCol>
			</CRow>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ProductEditThreeSixtyImage',
	props: {
		threeSixtyImages: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			localThreeSixtyImages: [],
			maxValue: 1,
		};
	},
	computed: {
		isMaxValue() {
			return this.localThreeSixtyImages.length >= this.maxValue;
		},
	},
	created() {
		this.localThreeSixtyImages = this.value.map((threeSixtyImage, index) => {
			return {
				...threeSixtyImage,
				key: this.generateKey(index),
			};
		});
	},
	methods: {
		generateKey(index) {
			return `${(new Date()).getTime()}-${index}`;
		},

		handleAddItem() {
			if (!this.isMaxValue) {
				this.localThreeSixtyImages.push({
					file: null,
					link: null,
					key: this.generateKey(this.localThreeSixtyImages.length),
				});

				this.emitInputValue();
			}
		},

		handleInputImage(file, index) {
			this.localThreeSixtyImages[index].file = file;
			this.emitInputValue();
		},

		handleDeleteItem(value) {
			this.localThreeSixtyImages = this.localThreeSixtyImages.filter((threeSixtyImage, index) => {
				return index !== value;
			});

			this.emitInputValue();
		},

		handleLinkChange(value, index) {
			this.localThreeSixtyImages[index].link = value;
			this.emitInputValue();
		},

		emitInputValue() {
			this.$emit('input', this.localThreeSixtyImages);
		},
	},
};
</script>

<style lang="scss" scoped>
	.link {
		color: $color-hypertext;
	}

	.three-sixty-image-item {
		margin-top: rem(16);
	}
</style>
