<template>
	<div data-test-id="product-group-container">
		<CRow>
			<CCol>
				<h4 class="my-4">
					Product group (Variations)
				</h4>

				<ProductRelated
					:products="groupProducts"
					:title="groupName"
					:has-variant="hasVariant"
					@editVariantGroup="$emit('editVariantGroup')"
				/>

				<h6 class="my-1">Product group name</h6>
				<p class="typo-body-2 color-black-45 mb-3">
					Display the product group name on the product detail page. If you leave it empty, the product name will be shown instead.
					<br>
					<i>*Important: The product group name will display for each product on the detail page*</i>
				</p>

				<CRow>
					<CCol>
						<CInput
							:value="groupTitle"
							data-test-id="group-title"
							label="Product group name"
							@input="(value) => $emit('update:groupTitle', value)"
						/>
					</CCol>
				</CRow>

				<CRow>
					<CCol>
						<CInput
							:value="groupSubTitle"
							data-test-id="group-sub-title"
							label="Group sub-title"
							@input="(value) => $emit('update:groupSubTitle', value)"
						/>
					</CCol>
				</CRow>

				<hr class="mb-4">
			</CCol>
		</CRow>
		<CRow>
			<!-- Need to get index 1 of refs because of product related is under product group section -->
			<CCol :ref="PRODUCT_REFS.RELATED[1]">
				<h4 class="my-4">
					Related products
				</h4>
				<ProductRelated
					:products="relatedProducts"
					data-test-id="product-group-related"
					title="Related products"
					description="Related products are alternative items with similar functions to the original. It can be from the same categories, or offered from other brands."
					@update:products="(value) => $emit('update:relatedProducts', value)"
				/>
				<ProductRelated
					:products="upSellProducts"
					data-test-id="product-group-up-sell"
					title="Up-sell products"
					description="An up-sell item is offered to the customer as a pricier or higher-quality alternative to the product the customer is looking at."
					@update:products="(value) => $emit('update:upSellProducts', value)"
				/>
				<ProductRelated
					:products="crossSellProducts"
					data-test-id="product-group-cross-sell"
					title="Cross-sell products"
					description="A cross-sell product can be items from different categories that can be used with the original product. or other items from the same brand."
					@update:products="(value) => $emit('update:crossSellProducts', value)"
				/>
				<hr class="mt-4">
			</CCol>
		</CRow>
		<CRow>
			<!-- Need to get index 1 of refs because of product related is under product group section -->
			<CCol :ref="PRODUCT_REFS.FREQUENTLY_BOUGHT_TOGETHER[1]">
				<h4 class="my-4">
					Frequently bought together products
				</h4>
				<ProductRelated
					:products="frequentlyBoughtTogetherProducts"
					data-test-id="product-group-frequently-bought"
					description="These products are to complement the original product and enhance user experience. These are more specific than the cross-sell items."
					is-draggable
					@update:products="(value) => $emit('update:frequentlyBoughtTogetherProducts', value)"
					@onDragged="(newList) => $emit('update:frequentlyBoughtTogetherProducts', newList)"
				/>
				<hr class="mt-4">
			</CCol>
		</CRow>
	</div>
</template>

<script>
import ProductRelated from '@/components/ProductRelated.vue';
import { PRODUCT_REFS } from '../enums/products';

export default {
	components: {
		ProductRelated,
	},
	props: {
		groupName: {
			type: String,
			default: null,
		},
		groupTitle: {
			type: String,
			default: '',
		},
		groupSubTitle: {
			type: String,
			default: '',
		},
		groupProducts: {
			type: Array,
			default: () => [],
		},
		relatedProducts: {
			type: Array,
			default: () => [],
		},
		upSellProducts: {
			type: Array,
			default: () => [],
		},
		crossSellProducts: {
			type: Array,
			default: () => [],
		},
		frequentlyBoughtTogetherProducts: {
			type: Array,
			default: () => [],
		},
		hasVariant: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			PRODUCT_REFS,
		};
	},
};
</script>
