<template>
	<div data-test-id="product-group-container">
		<CRow>
			<CCol>
				<h4 class="my-4">
					Apple Care+
				</h4>
				<ProductRelated
					:products="appleCare"
					data-test-id="apple-care"
					description="Add Apple Care+ SKU for this product."
					@update:products="(value) => $emit('update:appleCare', value)"
				/>
				<hr class="mb-4">
			</CCol>
		</CRow>
	</div>
</template>

<script>
import ProductRelated from '@/components/ProductRelated.vue';

export default {
	name: 'ProductEditAppleCare',
	components: {
		ProductRelated,
	},
	props: {
		appleCare: {
			type: Array,
			default: () => [],
		},
	},
};
</script>
