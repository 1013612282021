<template>
	<CRow data-test-id="seo-container">
		<CCol>
			<h4 class="my-4">
				SEO
			</h4>
			<CRow>
				<CCol>
					<label data-test-id="search-engine-indexing-label">Search engine indexing</label>
				</CCol>
				<CCol>
					<div class="form-group-switch">
						<CSwitch
							id="enabled-search-engine"
							:checked="isSearchEngineIndexingEnabled"
							variant="3d"
							size="sm"
							class="switch-custom"
							color="success"
							data-test-id="switch"
							@update:checked="handleChecked"
						/>
						<span class="typo-body-2">
							{{ isSearchEngineIndexingEnabled ? switchSeoLabels[0] : switchSeoLabels[1] }}
						</span>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CTextarea
						:value="title"
						data-test-id="seo-meta-title"
						label="Meta title (EN)"
						rows="2"
						@input="(value) => $emit('update:title', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CTextarea
						:value="keyword"
						data-test-id="seo-meta-keywords"
						label="Meta keywords (EN)"
						rows="3"
						@input="(value) => $emit('update:keyword', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CTextarea
						:value="description"
						data-test-id="seo-meta-description"
						label="Meta description (EN)"
						rows="6"
						@input="(value) => $emit('update:description', value)"
					/>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ProductEditSEO',

	props: {
		seo: {
			type: Object,
			default: () => ({}),
		},
		title: {
			type: String,
			default: null,
		},
		keyword: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		isSearchEngineIndexingEnabled: {
			type: Boolean,
			default: true, // default is true
		},
		switchSeoLabels: {
			type: Array,
			default: () => ['Enabled', 'Disabled'],
		},
	},

	methods: {
		handleChecked(value) {
			this.$emit('update:isSearchEngineIndexingEnabled', value);
		},
	},
};
</script>
