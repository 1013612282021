<template>
	<div data-test-id="true-trade-in-container">
		<h4 class="mt-4">
			TRUE / Trade in visibility
		</h4>
		<CRow class="mb-4">
			<CCol md="9">
				<div
					class="description"
					data-test-id="description"
				>
					<span class="typo-body-2">
						Display TRUE / Trade in section at the middle of the product detail page
					</span>
				</div>
			</CCol>
			<CCol md="3">
				<div class="form-group form-group-switch">
					<CSwitch
						:id="id"
						:checked="isTradeInTruemoveVisible"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						data-test-id="switch"
						@update:checked="(value) => $emit('update:isTradeInTruemoveVisible', value)"
					/>
					<label
						:for="id"
						data-test-id="switch-text"
					>
						{{ visibilityText }}
					</label>
				</div>
			</CCol>
		</CRow>
		<hr class="mb-4">
	</div>
</template>

<script>
import { VISIBILITY as mappingVisibility } from '../mapping/categories';

export default {
	name: 'ProductEditTrueTradeInVisibility',
	props: {
		id: {
			type: String,
			required: true,
		},
		isTradeInTruemoveVisible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		visibilityText() {
			const text = mappingVisibility[this.isTradeInTruemoveVisible] || '';
			return this.$t(text);
		},
	},
};
</script>


<style lang="scss" scoped>
.description {
	line-height: 1;

	> span {
		color: $color-black-45;
	}
}

.form-group-switch {
	display: flex;

	.switch-custom {
		margin-right: rem(12);
	}

	> label {
		font-size: rem(14);
		color: $color-black-90;
	}
}
</style>
