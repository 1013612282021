<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Store front visibility (Website)
			</h4>
			<StoreFrontVisibility
				id="product-list-visibility"
				data-test-id="product-list-visibility"
				label="Product list"
				image-url="/assets/images/product-list-annotation-image.svg"
				image-alt="Product List Visibility"
				description="This is a general display location for products in the website. Example homepage, category page, product browser, etc."
				:is-visible="isListVisible"
				@onChecked="(value) => $emit('update:isListVisible', value)"
			/>
			<StoreFrontVisibility
				id="product-detail-visibility"
				data-test-id="product-detail-visibility"
				label="Product detail"
				image-url="/assets/images/product-detail-annotation-image.svg"
				image-alt="Product Detail Visibility"
				description="This location is a detail page of product that customer use to inspect and buy the product"
				is-alternate-product-url
				:is-visible="isDetailVisible"
				:alternate-product-url="alternateProductUrl"
				@onChecked="(value) => $emit('update:isDetailVisible', value)"
				@onInputLink="(value) => $emit('update:alternateProductUrl', value)"
			/>
			<StoreFrontVisibility
				id="product-search-visibility"
				data-test-id="product-search-visibility"
				label="Search result"
				image-url="/assets/images/product-search-annotation-image.svg"
				image-alt="Product Search Visibility"
				description="This location is a search result product listing that customers will see when they are using the search function on the website."
				:is-visible="isSearchVisible"
				@onChecked="(value) => $emit('update:isSearchVisible', value)"
			/>
			<hr class="mt-5">
		</CCol>
	</CRow>
</template>

<script>
import StoreFrontVisibility from '@/components/StoreFrontVisibility.vue';

export default {
	name: 'ProductEditStoreFrontVisibility',
	components: {
		StoreFrontVisibility,
	},
	props: {
		isListVisible: {
			type: Boolean,
			default: false,
		},
		isDetailVisible: {
			type: Boolean,
			default: false,
		},
		isSearchVisible: {
			type: Boolean,
			default: false,
		},
		isAlternateProductUrl: {
			type: Boolean,
			default: true,
		},
		alternateProductUrl: {
			type: String,
			default: null,
		},
	},
};
</script>
