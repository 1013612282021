<template>
	<CRow>
		<CCol>
			<CRow>
				<CCol md="7">
					<h6>
						Product video
					</h6>
					<div class="typo-helper-text color-black-45">
						<span>
							Video will be shown in product detail page. Supports only Youtube link and allow only 5 video link for each product. Recommended cover image size is 1000x1000px.
						</span>
					</div>
				</CCol>

				<CCol md="5" class="text-right">
					<CButton
						:disabled="isMaxValue"
						class="transparent"
						color="secondary"
						data-test-id="add-video"
						@click="handleAddItem"
					>
						<CIcon class="mr-2" name="cil-plus" /> Add video
					</CButton>
				</CCol>
			</CRow>

			<CRow>
				<CCol>
					<BaseInputMedia
						v-for="(youtube, index) in localYoutubes"
						:key="youtube.key"
						:file="youtube.file"
						:link="youtube.link"
						:index="index"
						:label="`Video link #${index + 1}`"
						class="youtube-item"
						data-test-id="youtube-item"
						placeholder="Add Youtube link here"
						@onInputImage="handleInputImage"
						@onDelete="handleDeleteItem"
						@onLinkChange="handleLinkChange"
					/>
				</CCol>
			</CRow>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ProductEditYoutube',
	props: {
		youtubes: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			localYoutubes: [],
			maxValue: 5,
		};
	},
	computed: {
		isMaxValue() {
			return this.localYoutubes.length >= this.maxValue;
		},
	},
	created() {
		this.localYoutubes = this.value.map((youtube, index) => {
			return {
				...youtube,
				key: this.generateKey(index),
			};
		});
	},
	methods: {
		generateKey(index) {
			return `${(new Date()).getTime()}-${index}`;
		},

		handleAddItem() {
			if (!this.isMaxValue) {
				this.localYoutubes.push({
					file: null,
					link: null,
					key: this.generateKey(this.localYoutubes.length),
				});

				this.emitInputValue();
			}
		},

		handleInputImage(file, index) {
			this.localYoutubes[index].file = file;
			this.emitInputValue();
		},

		handleDeleteItem(value) {
			this.localYoutubes = this.localYoutubes.filter((youtube, index) => {
				return index !== value;
			});

			this.emitInputValue();
		},

		handleLinkChange(value, index) {
			this.localYoutubes[index].link = value;
			this.emitInputValue();
		},

		emitInputValue() {
			this.$emit('input', this.localYoutubes);
		},
	},
};
</script>

<style lang="scss" scoped>
	.youtube-item {
		margin-top: rem(16);
	}
</style>
