<template>
	<CRow class="mb-4">
		<CCol>
			<CRow class="mb-3">
				<CCol md="8">
					<h6 data-test-id="title">
						{{ title }}
					</h6>
					<div
						class="typo-body-2 color-black-45"
						data-test-id="description"
					>
						{{ description }}
					</div>
				</CCol>
				<CCol
					md="4"
					class="text-right"
				>
					<CButton
						color="secondary"
						class="btn btn-secondary"
						@click="$refs['modal-sku'].open()"
					>
						{{ products.length ? 'Edit' : 'Add' }} products
					</CButton>
				</CCol>
			</CRow>

			<CRow v-if="hasVariant" class="mb-3">
				<CCol>
					<h6 data-test-id="title">
						Variant group
					</h6>
				</CCol>
				<CCol
					lg="4"
					class="text-right"
				>
					<CButton
						color="secondary"
						class="btn btn-secondary"
						@click="$emit('editVariantGroup')"
					>
						Edit variant group
					</CButton>
				</CCol>
			</CRow>

			<CRow>
				<CCol
					v-if="products.length"
					lg="12"
				>
					<CRow>
						<CCol md="10">
							<span class="label">SKUs</span>
						</CCol>
						<CCol md="2">
							<span class="label pl-0">Status</span>
						</CCol>
					</CRow>
					<ul class="list-unstyled p-0">
						<draggable
							class="border rounded-sm border-secondary pt-3"
							data-test-id="product-group-sku"
							tag="li"
							:value="products"
							:disabled="!isDraggable"
							@input="handleUpdateOrder"
						>
							<CRow
								v-for="product in products"
								:key="product.sku"
								class="typo-body-2 d-flex px-3 text-decoration-none"
								:class="{'draggable':isDraggable}"
							>
								<CCol md="10" class="draggable-item">
									<SKUListItem
										:thumbnail="product.thumbnail"
										:name="product.name"
										:sku="product.sku"
										:is-primary="product.isPrimary"
									/>
								</CCol>
								<CCol md="2" class="draggable-item">
									<CBadge
										class="badge-status ml-2"
										:color="product.status.value"
									>
										{{ product.status.name }}
									</CBadge>
								</CCol>
							</CRow>
						</draggable>
					</ul>
				</CCol>
			</CRow>
		</CCol>
		<ModalSKU
			ref="modal-sku"
			:sku-list="products"
			title="Add products"
			@onConfirm="(value) => $emit('update:products', value)"
		/>
	</CRow>
</template>

<script>
import draggable from 'vuedraggable';
import ModalSKU from '@/components/ModalSKU.vue';
import SKUListItem from '@/components/SKUListItem.vue';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';

export default {
	name: 'ProductRelated',
	components: {
		draggable,
		ModalSKU,
		SKUListItem,
	},
	props: {
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		isDraggable: {
			type: Boolean,
			default: false,
		},
		hasVariant: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			IMAGE_PLACEHOLDER_URL,
			dummyList: [],
		};
	},
	watch: {
		products: {
			immediate: true,
			handler(list) {
				this.dummyList = list;
			},
		},
	},
	methods: {
		handleAdd() {
			this.$refs['modal-sku'].open();
		},
		handleUpdateOrder(sortedList) {
			if (!this.isDraggable) {
				return;
			}
			this.dummyList = sortedList;
			this.$emit('onDragged', this.dummyList);
		},
	},
};
</script>

<style lang="scss" scoped>
	.thumbnail {
		width: rem(40);
		height: rem(40);
		object-fit: contain;
	}

	.description {
		color: $color-black-45;
	}

	.badge-primary-product {
		background-color: $color-gray-400;
		color: $color-black-70;
	}

	.draggable {
		cursor: move;

		&:hover {
			.draggable-item {
				background-color: $color-gray-100;
			}
		}
	}
</style>

