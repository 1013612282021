<template>
	<div data-test-id="product-group-container">
		<CRow>
			<CCol>
				<h4 class="my-4">
					Must Have Product
				</h4>
				<ProductRelated
					:products="mustHaveProduct"
					data-test-id="must-have-product"
					description="Add Must-Have SKU for this product and will get only the first item list."
					@click="handleClick"
					@update:products="handleUpdateMustHaveProduct"
				/>
				<hr class="mb-4">
			</CCol>
		</CRow>
	</div>
</template>
<script>
import ProductRelated from '@/components/ProductRelated.vue';

export default {
	name: 'ProductEditMustHave',
	components: {
		ProductRelated,
	},
	props: {
		mustHaveProduct: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		handleClick() {},
		handleUpdateMustHaveProduct(value) {
			this.$emit('update:mustHaveProduct', value.length > 0
				// Get first item of list and convent to array
				? [value[0]]
				: []);
		},
	},
};
</script>
