import { render, staticRenderFns } from "./ProductEditCategory.vue?vue&type=template&id=377c856a&scoped=true&"
import script from "./ProductEditCategory.vue?vue&type=script&lang=js&"
export * from "./ProductEditCategory.vue?vue&type=script&lang=js&"
import style0 from "./ProductEditCategory.vue?vue&type=style&index=0&id=377c856a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377c856a",
  null
  
)

export default component.exports