<template>
	<div class="main-wrapper-large">
		<BaseLoading
			v-if="isLoading"
			is-full-page
		/>
		<CRow v-else-if="!isNotFound">
			<ProductEditSidebar @onClickNav="scrollToSection" />
			<CCol sm="8">
				<form
					class="form-product-edit mt-4"
					@submit.prevent="handleSubmit"
				>
					<CRow class="mb-5">
						<CCol>
							<CRow>
								<CCol>
									<header class="mb-4">
										<h4>{{ product.name }}</h4>
										<div class="typo-body-2">
											<span>Last updated by: </span> <a class="updated-by">{{ updatedBy | nullableToDashFormat }} </a> <span class="color-black-45">| {{ product.updatedDate }}</span>
										</div>
										<div class="typo-body-2">
											<span>Created from: </span> <span class="color-black-45">BNN system | {{ product.createdDate }}</span>
										</div>
									</header>
								</CCol>
							</CRow>
							<ProductEditStock
								:ref="PRODUCT_REFS.STOCK"
								:stock="stock"
							/>
							<ProductEditGeneralInfo
								:ref="PRODUCT_REFS.GENERAL_INFO"
								:product-name.sync="generalInfo.name"
								:slug.sync="generalInfo.slug"
								:sku.sync="generalInfo.sku"
								:apple-sku.sync="generalInfo.appleSku"
								:brand.sync="generalInfo.brand"
								:product-status.sync="generalInfo.status"
								:product-type.sync="generalInfo.type"
								:new-product-until.sync="generalInfo.newUntilAt"
								:uid="generalInfo.uid"
							/>
							<ProductEditStoreFrontVisibility
								:ref="PRODUCT_REFS.STORE_FRONT_VISIBILITY"
								:product="product"
								:is-list-visible.sync="visibility.isListVisible"
								:is-detail-visible.sync="visibility.isDetailVisible"
								:is-search-visible.sync="visibility.isSearchVisible"
								:alternate-product-url.sync="visibility.alternateProductUrl"
							/>
							<ProductEditPrice
								:ref="PRODUCT_REFS.PRICE"
								:price-srp="pricing.priceSRP"
								:price-min="pricing.priceMin"
								:price-selling.sync="pricing.priceSellings[CUSTOMER_GROUP.MEMBER].price"
								:price-selling-franchise.sync="pricing.priceSellings[CUSTOMER_GROUP.FRANCHISE].price"
								:is-itec-overwrite.sync="pricing.isItecOverwrite"
								:is-itec-overwrite-franchise.sync="pricing.isItecOverwriteFranchise"
							/>
							<ProductEditMedia
								:ref="PRODUCT_REFS.MEDIA"
								:images.sync="media.images"
								:youtubes.sync="media.youtubes"
								:three-sixty-images.sync="media.threeSixtyImages"
							/>
							<ProductEditDimension
								:ref="PRODUCT_REFS.DIMENSION"
								:length.sync="dimension.length"
								:width.sync="dimension.width"
								:height.sync="dimension.height"
								:weight.sync="dimension.weight"
							/>
							<ProductEditCategory
								:ref="PRODUCT_REFS.CATEGORY"
								:primary-id.sync="category.primaryId"
								:categories.sync="category.categories"
								:categories-itec="category.categoriesITEC"
								:primary-category-id.sync="category.primaryCategoryId"
							/>
							<ProductEditLabel
								:ref="PRODUCT_REFS.LABEL"
								:primary-labels.sync="label.primary"
								:secondary-labels.sync="label.secondary"
								:frame-labels.sync="label.frame"
								:badge-labels.sync="label.badge"
							/>
							<ProductEditDescription
								:ref="PRODUCT_REFS.DESCRIPTION"
								:short-description.sync="description.short"
								:full-description.sync="description.full"
								:short-attribute.sync="specification.shortAttribute"
							/>
							<ProductEditSpecification
								:ref="PRODUCT_REFS.SPECIFICATION"
								:attribute-set.sync="specification.attributeSet"
							/>
							<ProductEditWarranty
								:ref="PRODUCT_REFS.WARRANTY"
								:customer-warranty-days.sync="warranty.customerDays"
								:supplier-warranty-days.sync="warranty.supplierDays"
							/>
							<ProductEditSEO
								:ref="PRODUCT_REFS.SEO"
								:title.sync="seo.title"
								:keyword.sync="seo.keyword"
								:description.sync="seo.description"
								:is-search-engine-indexing-enabled.sync="seo.isSearchEngineIndexingEnabled"
							/>
							<ProductEditRelated
								:ref="PRODUCT_REFS.GROUP"
								:group-name="related.groupName"
								:group-title.sync="related.groupTitle"
								:group-sub-title.sync="related.groupSubTitle"
								:group-products="related.groupProducts"
								:related-products.sync="related.relatedProducts"
								:up-sell-products.sync="related.upSellProducts"
								:cross-sell-products.sync="related.crossSellProducts"
								:frequently-bought-together-products.sync="related.frequentlyBoughtTogetherProducts"
								:has-variant="product.hasVariant"
								@editVariantGroup="handleEditVariant"
							/>
							<ProductEditAppleCare
								:ref="PRODUCT_REFS.APPLE_CARE"
								:apple-care.sync="appleCare.appleProduct"
							/>
							<ProductEditTrueTradeInVisibility
								id="truemove-trade-in-visibility"
								:ref="PRODUCT_REFS.TRADE_IN_TRUEMOVE"
								:is-trade-in-truemove-visible.sync="visibility.isTruemoveTradeIn"
							/>
							<ProductEditMustHave
								:ref="PRODUCT_REFS.MUST_HAVE"
								:must-have-product.sync="mustHave.mustHaveProduct"
							/>
							<ProductEditRemark
								:ref="PRODUCT_REFS.REMARK"
								:list="product.remarks"
							/>
						</CCol>
					</CRow>

					<BaseActionPanelStickyFooter
						:disabled-confirm="edit.isUpdating"
						content-class="main-wrapper-large"
						is-edit
						data-test-id="save-change-product"
						@onConfirm="handleSubmit"
						@onCancel="$router.push({ name: 'Products'})"
					/>
				</form>
			</CCol>
		</CRow>
		<Error404 v-else />
	</div>
</template>

<script>
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductEditGeneralInfo from '@/components/ProductEditGeneralInfo.vue';
import ProductEditPrice from '@/components/ProductEditPrice.vue';
import ProductEditMedia from '@/components/ProductEditMedia.vue';
import ProductEditDimension from '@/components/ProductEditDimension.vue';
import ProductEditCategory from '@/components/ProductEditCategory.vue';
import ProductEditStoreFrontVisibility from '@/components/ProductEditStoreFrontVisibility.vue';
import ProductEditDescription from '@/components/ProductEditDescription.vue';
import ProductEditSpecification from '@/components/ProductEditSpecification.vue';
import ProductEditWarranty from '@/components/ProductEditWarranty.vue';
import ProductEditSEO from '@/components/ProductEditSEO.vue';
import ProductEditRelated from '@/components/ProductEditRelated.vue';
import ProductEditRemark from '@/components/ProductEditRemark.vue';
import ProductEditLabel from '@/components/ProductEditLabel.vue';
import ProductEditStock from '@/components/ProductEditStock.vue';
import ProductEditSidebar from '@/components/ProductEditSidebar.vue';
import ProductEditAppleCare from '@/components/ProductEditAppleCare.vue';
import ProductEditTrueTradeInVisibility from '@/components/ProductEditTrueTradeInVisibility.vue';
import ProductEditMustHave from '@/components/ProductEditMustHave.vue';
import Error404 from '@/components/Error404.vue';

import { PRODUCT_RELATED_FIELDS } from '../enums/productRelated';
import { CUSTOMER_GROUP } from '../enums/customerGroups';
import { PRODUCT_REFS, PRODUCT_TYPES } from '../enums/products';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';
import { priceToAPI, pathOr, scrollToTop, convertDateTimeToUTC } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'ProductEdit',
	components: {
		ProductEditGeneralInfo,
		ProductEditPrice,
		ProductEditMedia,
		ProductEditDimension,
		ProductEditCategory,
		ProductEditDescription,
		ProductEditSpecification,
		ProductEditStoreFrontVisibility,
		ProductEditWarranty,
		ProductEditSEO,
		ProductEditRelated,
		ProductEditRemark,
		ProductEditLabel,
		ProductEditStock,
		ProductEditSidebar,
		ProductEditAppleCare,
		ProductEditTrueTradeInVisibility,
		ProductEditMustHave,
		Error404,
	},
	data() {
		return {
			isLoading: false,
			PRODUCT_RELATED_FIELDS,
			productId: this.$route.params.id,
			generalInfo: {
				name: null,
				slug: null,
				sku: null,
				appleSku: null,
				brand: {},
				status: null,
				type: null,
				newUntilAt: null,
				isAllowFreebie: false,
			},
			pricing: {
				priceSRP: null,
				priceMin: null,
				isItecOverwrite: null,
				priceSellings: {},
			},
			dimension: {
				length: null,
				width: null,
				height: null,
				weight: null,
			},
			category: {
				primaryId: null,
				categories: [],
				categoriesITEC: [],
				primaryCategoryId: null,
			},
			description: {
				short: null,
				long: null,
			},
			specification: {
				attributeSet: {},
				shortAttribute: null,
			},
			warranty: {
				customerDays: null,
				supplierDays: null,
			},
			seo: {
				title: null,
				description: null,
				keyword: null,
				isSearchEngineIndexingEnabled: true, // default is true
			},
			stock: {
				total: 0,
				available: 0,
				reserved: 0,
				committed: 0,
				reservedAndCommitted: 0,
			},
			visibility: {
				isListVisible: false,
				isDetailVisible: false,
				isSearchVisible: false,
				isTruemoveTradeIn: false,
				alternateProductUrl: null,
			},
			label: {
				primary: [],
				secondary: [],
				frame: [],
				badge: [],
			},
			related: {
				groupName: null,
				groupTitle: null,
				groupSubTitle: null,
				groupProducts: [],
				relatedProducts: [],
				upSellProducts: [],
				crossSellProducts: [],
				frequentlyBoughtTogetherProducts: [],
			},
			appleCare: {
				appleProduct: [],
			},
			mustHave: {
				mustHaveProduct: [],
			},
			media: {
				images: [],
				threeSixtyImages: [],
				youtubes: [],
			},
		};
	},
	computed: {
		...mapState('products', {
			edit: 'edit',
		}),
		...mapGetters({
			product: 'products/productInfo',
		}),
		CUSTOMER_GROUP() {
			return CUSTOMER_GROUP;
		},
		PRODUCT_REFS() {
			return PRODUCT_REFS;
		},
		updatedBy() {
			return this.product.updatedBy
				? this.product.updatedBy.username
				: null;
		},
		isNotFound() {
			return isEmpty(this.edit) || isEmpty(this.edit.data);
		},
	},
	async created() {
		this.isLoading = true;
		await this.getProduct(this.productId);
		this.isLoading = false;

		this.generalInfo = {
			name: this.product.name,
			slug: this.product.slug,
			sku: this.product.sku,
			appleSku: this.product.appleSku,
			brand: {
				name: this.product.brand.name,
				value: this.product.brand.id,
			},
			status: this.product.status.value,
			type: this.product.productType,
			newUntilAt: this.product.newProductUntil
				? dayjs(this.product.newProductUntil, 'DD/MM/YYYY').toDate()
				: null,
			isAllowFreebie: this.product.allowFreebie,
			uid: this.product.uid,
		};

		this.media = {
			images: this.product.files,
			threeSixtyImages: this.product.threeSixtyImages,
			youtubes: this.product.youtubes,
		};

		this.pricing = {
			priceSRP: this.product.priceSRP,
			priceMin: this.product.priceMin,
			isItecOverwrite: this.product.isAllowedITECOverridePrice,
			isItecOverwriteFranchise: this.product.isAllowedITECOverridePriceFranchise,
			priceSellings: this.product.priceSellings,
			priceSellingFranchise: this.product.priceSellingFranchise,
		};

		this.description = {
			short: this.product.description.short,
			full: this.product.description.full,
		};

		this.specification = {
			attributeSet: this.product.attributeSet,
			shortAttribute: this.product.attributeSet.shortAttribute,
		};

		this.dimension = {
			length: this.product.dimension.dimensionLength,
			width: this.product.dimension.width,
			height: this.product.dimension.height,
			weight: this.product.dimension.weight,
		};

		const primaryCategory = this.product.categories.find((category) => category.is_primary === 1);

		this.category = {
			primaryId: primaryCategory ? primaryCategory.id : null,
			categories: this.product.categories,
			categoriesITEC: this.product.categoriesITEC,
			primaryCategoryId: this.product.primaryCategoryId,
		};

		this.warranty = {
			customerDays: this.product.warranty.warrantyCustomer,
			supplierDays: this.product.warranty.warrantySupplier,
		};

		this.seo = {
			title: this.product.seo.title,
			keyword: this.product.seo.keyword,
			description: this.product.seo.description,
			isSearchEngineIndexingEnabled: this.product.seo.isSearchEngineIndexingEnabled,
		};

		this.stock = {
			total: this.product.stockTotal,
			reservedAndCommitted: this.product.stockReservedAndCommitted,
			available: this.product.stockAvailable,
		};

		this.visibility = {
			isListVisible: this.product.isListVisible,
			isDetailVisible: this.product.isDetailVisible,
			isSearchVisible: this.product.isSearchVisible,
			isTruemoveTradeIn: this.product.isTruemoveTradeIn,
			alternateProductUrl: this.product.alternateProductUrl,
		};

		this.label = {
			primary: this.product.labels.filter((item) => item.type === PRODUCT_LABEL_TYPES.primary),
			secondary: this.product.labels.filter((item) => item.type === PRODUCT_LABEL_TYPES.secondary),
			frame: this.product.labels.filter((item) => item.type === PRODUCT_LABEL_TYPES.frame),
			badge: this.product.labels.filter((item) => item.type === PRODUCT_LABEL_TYPES.badge),
		};

		this.related = {
			groupName: this.product.groupName,
			groupTitle: this.product.groupTitle,
			groupSubTitle: this.product.groupSubTitle,
			groupProducts: this.product.groupProducts,
			relatedProducts: this.product.relatedProducts,
			upSellProducts: this.product.upSellProducts,
			crossSellProducts: this.product.crossSellProducts,
			frequentlyBoughtTogetherProducts: this.product.frequentlyBoughtTogetherProducts,
		};

		this.appleCare = {
			appleProduct: this.product.appleCare,
		};

		this.mustHave = {
			// Have to convent data to array because product edit must have
			// and product related using loop for to display product item
			mustHaveProduct: this.product.mustHave.id
				? [this.product.mustHave]
				: [],
		};
	},
	methods: {
		...mapActions({
			getProduct: 'products/getProduct',
			updateProduct: 'products/updateProduct',
			showToast: 'toast/showToast',
		}),
		async scrollToSection(refNames = []) {
			const element = refNames.reduce((result, current) => {
				if (result) {
					return result.$refs[current];
				}
				return this.$refs[current];
			}, null);

			if (element) {
				await this.$nextTick();
				if (element instanceof Element) {
					element.scrollIntoView({ behavior: 'smooth' });
				} else {
					element.$el.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},
		handleEditVariant() {
			const slug = this.product.groupSlug;
			if (slug) {
				this.$router.push({
					name: ROUTE_NAME.PRODUCT_EDIT_VARIANT_GROUP,
					params: {
						slug,
					},
				});
			}
		},
		async handleSubmit() {
			const refGeneralInfo = this.$refs['product-general-info'];
			const refDescription = this.$refs['product-description'];

			refGeneralInfo.$v.$touch();
			refDescription.$v.$touch();

			if (refGeneralInfo.$v.$invalid) {
				scrollToTop();
				return;
			}

			if (refDescription.$v.$invalid) {
				this.scrollToSection(['product-description']);
				return;
			}

			const id = this.productId;
			const params = {
				// General Info
				name: this.generalInfo.name,
				slug: this.generalInfo.slug,
				status: this.generalInfo.status,
				type: this.generalInfo.type,
				brand_id: this.generalInfo.brand.value,
				new_until_at: this.generalInfo.newUntilAt
					// Need to add 23:59:59 to affect end of day of target date
					? convertDateTimeToUTC(this.generalInfo.newUntilAt, '23:59:59')
					: null,
				is_allowed_freebie: this.generalInfo.type === PRODUCT_TYPES.FREEBIE,
				// Price
				is_allowed_itec_override_price: this.pricing.isItecOverwrite ? 1 : 0,
				is_allowed_itec_override_franchise_price: this.pricing.isItecOverwriteFranchise ? 1 : 0,
				price_sellings: Object.values(this.pricing.priceSellings).map((value) => {
					return {
						customer_group_id: value.customerGroupId,
						store_id: value.storeId,
						price: priceToAPI(value.price),
					};
				}),
				// Media
				file_ids: this.media.images.map((file) => file.id),
				three_sixty_images: this.media.threeSixtyImages.map((threeSixtyImage) => {
					return {
						file_id: pathOr(null, ['file', 'id'])(threeSixtyImage),
						link: threeSixtyImage.link,
					};
				}),
				youtubes: this.media.youtubes.map((youtube) => {
					return {
						file_id: pathOr(null, ['file', 'id'])(youtube),
						link: youtube.link,
					};
				}),
				// Dimension
				weight: this.dimension.weight,
				width: this.dimension.width,
				length: this.dimension.length,
				height: this.dimension.height,

				// Category
				category_ids: this.category.categories.map((category) => category.id),
				primary_category_id: this.category.primaryId,

				// Store front visibility
				is_detail_visible: !!this.visibility.isDetailVisible,
				is_list_visible: !!this.visibility.isListVisible,
				is_search_visible: !!this.visibility.isSearchVisible,
				is_trade_in_truemove_visible: !!this.visibility.isTruemoveTradeIn,
				alternate_product_url: this.visibility.alternateProductUrl,

				// Label
				label_ids: [
					...this.label.primary.map((label) => label.id),
					...this.label.secondary.map((label) => label.id),
					...this.label.frame.map((label) => label.id),
					...this.label.badge.map((label) => label.id),
				],
				// Description
				short_description: this.description.short,
				full_description: this.description.full,
				// Specification
				attribute_set_id: this.specification.attributeSet.id,
				attribute_keys: this.specification.attributeSet.attributeKeys,
				short_attribute: this.specification.shortAttribute,
				// Warranty
				customer_warranty_days: this.warranty.customerDays,
				supplier_warranty_days: this.warranty.supplierDays,
				// SEO
				meta_title: this.seo.title,
				meta_keyword: this.seo.keyword,
				meta_description: this.seo.description,
				is_search_engine_indexing_enabled: this.seo.isSearchEngineIndexingEnabled,
				// Product Group
				related_product_ids: this.related.relatedProducts.map((product) => Number(product.id)),
				upsell_product_ids: this.related.upSellProducts.map((product) => Number(product.id)),
				cross_sell_product_ids: this.related.crossSellProducts.map((product) => Number(product.id)),
				frequently_bought_together_products: this.related.frequentlyBoughtTogetherProducts.map((product, index) => {
					return {
						id: Number(product.id),
						order: index + 1,
					};
				}),
				// Apple Care
				apple_care_product_ids: this.appleCare.appleProduct.map((product) => Number(product.id)),
				// Must Have Product
				must_have_product_id: this.mustHave?.mustHaveProduct.length > 0
					// Have to send only id of product item
					? this.mustHave?.mustHaveProduct[0].id
					: null,

				// Product group
				product_group_title: this.related.groupTitle,
				product_group_sub_title: this.related.groupSubTitle,
			};

			await this.updateProduct({ id, params });
		},

	},
};
</script>

<style lang="scss" scoped>
	.date-time {
		@include typo-helper-text;

		color: $color-black-45;
		margin-bottom: 4px;
	}

	.updated-by {
		color: $color-hypertext;
	}
</style>
