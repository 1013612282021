<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Category
			</h4>
			<div class="categories-itec mb-4">
				<label>Category info from ITEC</label>
				<div class="category-itec">
					<span class="mr-4">Com7 Category: {{ categoriesItec.main }}</span> <span>Com7 Sub category: {{ categoriesItec.sub }}</span>
				</div>
			</div>
			<div class="d-flex align-items-start justify-content-between mb-3">
				<div class="flex-fill mr-3">
					<h6 class="my-1">Category</h6>
					<p class="typo-body-2 color-black-45 mb-0">Set primary category by clicking on category name.</p>
				</div>
				<CButton
					color="secondary"
					data-test-id="add-categories"
					@click="$refs['modal-category'].open()"
				>
					{{ getSelectedCategories.length ? 'Edit' : 'Add' }} category
				</CButton>
			</div>
			<PreviewBoxCategory
				v-if="categories.length"
				:categories="getSelectedCategories"
				:primary-id="primaryId"
				able-to-set-primary
				@onSelectPrimaryCategory="handleSetPrimaryCategory"
			/>
			<BaseNoItemBanner
				v-else
				text="There are no category for this product"
			/>
			<hr class="mt-5">
		</CCol>
		<ModalCategory
			ref="modal-category"
			title="Select categories"
			:selected-ids="selectedIds"
			:enable-select-all="false"
			@onSubmit="handleSubmitCategory"
			@onCancel="handleCancelCategoryModal"
		/>
	</CRow>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalCategory from '@/components/ModalCategory.vue';
import PreviewBoxCategory from '@/components/PreviewBoxCategory.vue';

export default {
	name: 'ProductEditCategory',
	components: {
		ModalCategory,
		PreviewBoxCategory,
	},
	props: {
		primaryId: {
			type: Number,
			default: null,
		},
		categories: {
			type: Array,
			default: () => [],
		},
		categoriesItec: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		...mapGetters({
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),
		selectedIds() {
			return this.categories.map((category) => category.id);
		},
	},
	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		handleCancelCategoryModal() {
			// Reset category data when user close/cancel category modal
			this.preSelectCategories(this.selectedIds);
		},
		handleSetPrimaryCategory(id) {
			this.$emit('update:primaryId', id);
		},
		handleSubmitCategory(categories = []) {
			// Reset primary if not found
			if (!categories.length) {
				this.$emit('update:primaryId', null);
			} else {
				const categoryIds = categories.map((category) => category.id);
				const isPrimaryExist = categoryIds.find((categoryId) => categoryId === this.primaryId);

				// Auto assign primary id if not exist in the new categories (Assign to min id in case if more than 1 category)
				if (!isPrimaryExist) {
					this.$emit('update:primaryId', Math.min(...categoryIds));
				}
			}

			this.$emit('update:categories', categories);
		},
	},
};
</script>

<style lang="scss" scoped>
	.category-list {
		padding-left: 0;
		list-style: none;

		// .category-list-empty
		&-empty,
		li {
			@include typo-body-2;
		}
	}

	.category-itec {
		span {
			@include typo-body-2;

			color: $color-black-45;
		}
	}
</style>
