<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Media
			</h4>
			<ProductEditImage
				:files="images"
				class="mb-5"
				@onFileChange="(value) => $emit('update:images', value)"
			/>
			<ProductEditYoutube
				:value="youtubes"
				class="mb-5"
				@input="(value) => $emit('update:youtubes', value)"
			/>
			<ProductEditThreeSixtyImage
				:value="threeSixtyImages"
				class="mb-5"
				@input="(value) => $emit('update:threeSixtyImages', value)"
			/>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import ProductEditImage from '@/components/ProductEditImage.vue';
import ProductEditThreeSixtyImage from '@/components/ProductEditThreeSixtyImage.vue';
import ProductEditYoutube from '@/components/ProductEditYoutube.vue';

export default {
	name: 'ProductEditMedia',
	components: {
		ProductEditImage,
		ProductEditThreeSixtyImage,
		ProductEditYoutube,
	},
	props: {
		images: {
			type: Array,
			default: () => [],
		},
		youtubes: {
			type: Array,
			default: () => [],
		},
		threeSixtyImages: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		handleFileChange(files = []) {
			this.images = files;
		},
		handleDeleteImage(id = null) {
			if (id) {
				this.productImages = this.productImages.filter((image) => image.id !== id);

				this.$emit('onFileChange', this.productImages);
			}
		},
	},
};
</script>
