<template>
	<div class="product-stock">
		<h5>Stock</h5>
		<div class="stock-detail mr-4">
			<label class="color-black-45">Total qty.:</label>
			<span class="stock-qty typo-body-2"> {{ stock.total | numberFormat(true) }}</span>
		</div>
		<div class="stock-detail mr-4">
			<label class="color-black-45">Reserved + Committed:</label>
			<span class="stock-qty typo-body-2"> {{ stock.reservedAndCommitted | numberFormat(true) }}</span>
		</div>

		<div class="stock-detail">
			<label class="color-black-45">Available stock:</label>
			<span class="stock-qty typo-body-2"> {{ stock.available | numberFormat(true) }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProductEditStock',

	props: {
		stock: {
			type: Object,
			default: () => ({}),
		},
	},
};
</script>

<style lang="scss" scoped>
	.stock-detail {
		display: inline-block;
	}
</style>
