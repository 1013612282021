<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Dimension and weight
			</h4>
			<CRow>
				<CCol lg="4">
					<BaseInputNumber
						:value="length"
						:decimal-scale="2"
						label="Length (cm)"
						data-test-id="dimension-length"
						@input="(value) => $emit('update:length', value)"
					/>
				</CCol>
				<CCol lg="4">
					<BaseInputNumber
						:value="width"
						:decimal-scale="2"
						label="Width (cm)"
						data-test-id="dimension-width"
						@input="(value) => $emit('update:width', value)"
					/>
				</CCol>
				<CCol lg="4">
					<BaseInputNumber
						:value="height"
						:decimal-scale="2"
						label="Height (cm)"
						data-test-id="dimension-height"
						@input="(value) => $emit('update:height', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="4">
					<BaseInputNumber
						:value="weight"
						:decimal-scale="2"
						label="Weight (kg)"
						data-test-id="dimension-weight"
						@input="(value) => $emit('update:weight', value)"
					/>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ProductEditDimension',

	props: {
		length: {
			type: [String, Number],
			default: null,
		},
		width: {
			type: [String, Number],
			default: null,
		},
		height: {
			type: [String, Number],
			default: null,
		},
		weight: {
			type: [String, Number],
			default: null,
		},
	},
};
</script>
